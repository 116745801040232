import React from 'react';
import styled from 'styled-components';

const Footer = styled.footer`
  margin-top: ${props => props.mode === 'desktop' ? 10 : 5}em;
  padding: 0.75em 0.5em;
  color: ${props => props.mode === 'plain' ? '#222' : 'whitesmoke'};
  font-family: Jura, sans-serif;
  font-size: 0.825em;
  font-weight: 400;
  text-align: center;
  position: relative;
  z-index: 0;

  a {
    color: ${props => props.mode === 'plain' ? '#222' : 'whitesmoke'};
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: ${props => props.mode === 'plain' ? '000' : 'white'};
      text-decoration: none;
    }
  }

  &:before {
    content: '';
    border-top: 1px solid ${props => props.mode === 'plain' ? '#222' : 'whitesmoke'};
    position: absolute;
    left: 50%;
    width: 10em;
    height: 1px;
    top: 0;
    transform: translateX(-50%);
    clear: both;
  }
`;

const FooterComponent = (props) => {
  return (
    <Footer
      mode={props.mode}
    >
      Built by <strong>Davide Cassenti</strong> with <a href="https://create-react-app.dev/" title="Create React App" rel="noopener noreferrer" target="_blank">Create React App</a>
    </Footer>
  )
}

export default FooterComponent;
