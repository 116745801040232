import React from 'react';
import styled from 'styled-components';

const Browser = styled.article`
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.section`
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  height: 2em;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
`;

const TitleBar = styled.div`
  background-color: white;
  border: 1px solid gray;
  flex-grow: 1;
  margin: 0.125em;
  height: 1em;
  font-size: 0.5em;
  padding: 0.5em;
`;

const Body = styled.section`
  background-color: white;
  flex-grow: 1;
  overflow: auto;
`;

const BrowserComponent = React.forwardRef((props, ref) => {
  return (
    <Browser className="browser">
      <Header className="browser__header">
        <Buttons className="browser__buttons">
          <Button className="browser__button-back">&larr;</Button>
          <Button className="browser__button-forward">&rarr;</Button>
        </Buttons>
        <TitleBar className="browser__titlebar">{props.url}</TitleBar>
      </Header>
      <Body className="browser__body" ref={ref} onScroll={props.onScroll}>{props.children}</Body>
    </Browser>
  );
});

export default BrowserComponent;
