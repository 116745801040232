import { useState, useEffect } from 'react';

export const useScreenSize = () => {
  const sizes = () => ({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight,
  });

  const [windowSize, setWindowSize] = useState(sizes());

  useEffect(() => {
    const setWindowSizeFromEvent = e => setWindowSize(sizes());

    window.addEventListener('resize', setWindowSizeFromEvent);

    return () => {
      window.removeEventListener('resize', setWindowSizeFromEvent);
    }
  }, []);

  return windowSize;
};
