import React from 'react';
import styled, { css } from 'styled-components';

import { text2react } from '../../helpers/text2react';

const cols = (name, length) => `${`${name} `.repeat(length).trim()}`;

const createRemainingColumns = (name, start, length) => {
  let curLength = length;
  let rem = Math.min(4, curLength);
  let cur = start;
  let columns = [];
  while (rem > 0) {
    columns.push(
      rem === 1 ? `"${cols(`${name}${cur}`, 12)}"` :
      rem === 2 ? `"${cols(`${name}${cur}`, 6)} ${cols(`${name}${cur + 1}`, 6)}"` :
      rem === 3 ? `"${cols(`${name}${cur}`, 4)} ${cols(`${name}${cur + 1}`, 4)} ${cols(`${name}${cur + 2}`, 4)}"` :
      `"${cols(`${name}${cur}`, 3)} ${cols(`${name}${cur + 1}`, 3)} ${cols(`${name}${cur + 2}`, 3)} ${cols(`${name}${cur + 3}`, 3)}"`
    );

    start += 1;
    cur = cur + rem;
    curLength -= 4;

    rem = Math.min(4, curLength);
  };

  return columns.join('');
};

const Newspaper = styled.section`
  display: grid;
  grid-template-areas:
    "tt tt tt tt tt tt tt tt tt tt tt tt"
    "hl hl hl hl hl hl hl hl hl hl hl hl"
    "n1 n1 n1 n1 n1 n1 n2 n2 n2 n3 n3 n3"
    "n1 n1 n1 n1 n1 n1 n4 n4 n4 n4 n4 n4"
    ${props => createRemainingColumns('n', 5, props.columns - 4)}
`;

const NewsTitle = styled.h1`
  grid-area: tt;
  color: black;
  font-family: UnifrakturMaguntia, serif;
  text-align: center;
  font-size: 2em;
  margin: 0;
  padding: 0;
`;

const NewsHeadline = styled.h2`
  grid-area: hl;
  font-family: "Source Serif Pro", serif;
  color: black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 0.5em;
  text-align: center;
  padding: 0.125em 0;

  &:after {
    content: '${props => props.date}';
    position: absolute;
    right: 1em;
  }
`;

const Article = styled.article`
  grid-area: n${[props => props.news]};
  box-sizing: border-box;
  color: black;
  font-family: "Source Serif Pro", serif;

  padding: 0.375em;
  position: relative;

  ${props => props.news > 3 ? css`
    &:before {
      content: '';
      border-top: 1px solid gray;
      position: absolute;
      top: 0;
      left: 0.125em;
      right: 0.125em;
    }
  ` : ''}

  &:after {
    content: '';
    border-right: 1px solid gray;
    position: absolute;
      right: 0;
      top: 0.125em;
      bottom: 0.125em;
  }
  
  &:nth-child(5),
  &:nth-child(4n + 2),
  &:last-child {
    &:after { content: none; }
  }

  h1 {
    font-size: 0.65em;
    padding: 0;
    margin: 0 0 0.75em;
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 0.5em;
    text-align: justify;
  }
`;

const NewsImage = styled.div`
  padding: 0;
  font-size: 0;
  margin: 0 0 8px;
  position: relative;

  &::after {
    content: '';
    background-image: linear-gradient(45deg, white 25%, transparent 25%), linear-gradient(-45deg, white 25%, transparent 25%), linear-gradient(45deg, transparent 75%, white 75%), linear-gradient(-45deg, transparent 75%, white 75%);
    background-size: 2px 2px;
    background-position: 0 0, 0 1px, 1px -1px, -1px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.25;
  }

  img {
    width: 100%;
    opacity: 1;
    filter: blur(0.1px) grayscale(100%);
  }
`;

const NewspaperComponent = (props) => {
  return (
    <Newspaper className="newspaper" columns={props.sections.length}>
      <NewsTitle>{props.title}</NewsTitle>
      <NewsHeadline
        date={`${(new Date().getDate())} ${(new Date()).toLocaleString('default', { month: 'long' })} ${1984 - ((new Date()).getFullYear() - 1984)}`}
      >{props.headline}</NewsHeadline>
      {props.sections.map((c, index) => (
        <Article columns={props.sections.length} key={c.title} news={index + 1}>
          <h1>{c.title}</h1>
          {c.image ? (
            <NewsImage><img alt={c.title} src={c.image} /></NewsImage>
          ) : null}
          <p>{text2react(c.text)}</p>
        </Article>
      ))}
    </Newspaper>
  )
};

export default NewspaperComponent;
