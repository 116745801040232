export const throttle = (fn, time) => {
  let invokeTime = Date.now();
  return (...args) => {
    const now = Date.now();
    if (now - invokeTime < time) return;

    invokeTime = now;

    return fn(...args);
  };
};