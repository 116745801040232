import React from 'react';
import styled from 'styled-components';

import { text2react } from '../../helpers/text2react';
import Page from './plain/Page';
import PlainHeader from './plain/PlainHeader';
import ScrollingImage from '../ScrollingImage';
import { useElementScroll } from '../../hooks/useElementScroll';


const PlainSection = styled.section`
  color: black;
  white-space: pre-wrap;
`;


const PlainSite = props => {
  const windowScroll = useElementScroll(props.scrollingElement);

  return (
    <>
      <PlainHeader
        className="plain-header"
        title={props.title}
        headline={props.headline}
      />
      <PlainSection className="plain-header__section">
        {props.sections.map((c, index) => (
          <Page key={c.title} titleColor={c.contrastColor} titleBackgroundColor={c.color}>
            <h1>{c.title}</h1>
            {c.image ? (
              <ScrollingImage
                src={c.image}
                pageScrollTop={windowScroll}
              />
            ) : null}
            <p>{text2react(c.text)}</p>
          </Page>
        ))}
      </PlainSection>
    </>
  );
};

export default PlainSite;
