import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import Box from '../../Box';
import { diskInsertAnimation, diskEjectAnimation } from '../../../helpers/animations';


const getLabelStyle = () => css`
  box-sizing: border-box;
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15%;
  right: 15%;
  border: 1px outset white;
`;

const Metal = styled.div`
  ${getLabelStyle}

  background-color: lightgray;
  border: 1px outset white;
  top: 0;
  height: 6em;
  left: 20%;
  right: 20%;

  &:after {
    content: '';
    background-color: ${props => props.color};
    border: 1px inset ${props => props.color};
    width: 1.25em;
    height: 3em;
    right: 1em;
    top: 1.5em;
    position: absolute;
  }
`;

const TopMetal = styled.div`
  ${getLabelStyle}

  background-color: lightgray;
  top: 0;
  bottom: 0;
  left: 20%;
  right: 20%;
`;

const FrontLabel = styled.p`
  ${getLabelStyle}

  bottom: 0;
  font-family: Jura, monospace;
  font-size: 150%;
  font-weight: 700;
  height: 6em;
  text-align: center;
  white-space: pre-wrap;
`;

const SideLabel = styled.p`
  ${getLabelStyle}

  top: 0;
  bottom: 0;
  font-family: Jura, monospace;
  font-size: 50%;
`;

const BackLabel = styled.p`
   ${getLabelStyle}

  bottom: 0;
  height: 15%;
`;

const Hole = styled.div`
  background-color: ${props => props.color};
  filter: brightness(0.9);
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  border: 1px inset ${props => props.color};
`;

const allAnimationsProps = {
  insert: {
    name: diskInsertAnimation,
    duration: 3
  },
  eject: {
    name: diskEjectAnimation,
    duration: 1.5
  }
};

const setAnimation = props => {
  const animationProps = allAnimationsProps[props.animation];

  return animationProps ? css`
    animation-name: ${animationProps.name(props.position, props.rotation)};
    animation-duration: ${animationProps.duration}s;
    animation-fill-mode: forwards;
  ` : '';
};

const Disk = styled(Box)`
  cursor: pointer;

  ${props => setAnimation(props)}
`;

const DiskComponent = (props) => {
  const { onClick, onAnimationEnd, animation, index, nextIndex } = props;

  const onDiskClick = useCallback(() => {
    onClick(index)
  }, [onClick, index]);

  const onDiskAnimationEnd = useCallback(() => {
    onAnimationEnd(animation === 'insert' ? index : -1);
    onClick(nextIndex);
  }, [animation, onAnimationEnd, onClick, index, nextIndex]);

  return (
    <Disk
      animation={props.animation}

      width={props.width}
      height={props.height}
      depth={props.depth}

      backgroundColor={props.color}

      onClick={onDiskClick}
      onAnimationEnd={onDiskAnimationEnd}

      contents={{
        front: (
          <>
            <Metal color={props.color} />
            <FrontLabel>{`Disk ${props.index + 1}\n${props.title}`}</FrontLabel>
            <Hole color={props.color} />
          </>
        ),
        bottom: (
          <SideLabel>Disk {props.index + 1}: {props.title}</SideLabel>
        ),
        back: (
          <>
            <BackLabel />
            <Metal color={props.color} />
            <Hole color={props.color} />
          </>
        ),
        top: (
          <TopMetal />
        )
      }}
      position={props.position}
      rotation={props.rotation}
      styles={{
        all: {
          filter: `brightness(0.9)`,
        },
        front: {
          filter: `brightness(1)`,
        },
        back: {
          filter: `brightness(1)`,
        }
      }}
    />
  );
};

export default DiskComponent;
