import { useState, useEffect } from 'react';

export const useElementScroll = (element) => {
  const scrollingElement = element || window;
  const scrollTopElement = scrollingElement === window ? document.scrollingElement : scrollingElement;
  const [scroll, setScroll] = useState(scrollTopElement.scrollTop);

  useEffect(() => {
    const setScrollFromEvent = () => setScroll(scrollTopElement.scrollTop);

    scrollingElement.addEventListener('scroll', setScrollFromEvent);

    return () => {
      scrollingElement.removeEventListener('scroll', setScrollFromEvent);
    }
  }, [scrollingElement, scrollTopElement]);

  return scroll;
};
