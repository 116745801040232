import React from 'react';
import styled, { css } from 'styled-components';

const getWallSize = (props) => {
  switch (props.side) {
    case 'front':
    case 'back':
      return { width: props.width, height: props.height };

    case 'left':
    case 'right':
      return { width: props.depth, height: props.height };

    case 'top':
    case 'bottom':
      return { width: props.width, height: props.depth };

    default: return {};
  }
};

const getWallRotation = (props) => {
  switch (props.side) {
    case 'left':
    case 'right':
      return { x: 0, y: 90, z: 0 };

    case 'top':
    case 'bottom':
      return { x: -90, y: 0, z: 0 };

    default: return { x: 0, y: 0, z: 0 };
  }
}

const getWallPosition = (props) => {
  switch (props.side) {
    case 'front':
      return { x: 0, y: 0, z: 0 };

    case 'back':
      return { x: 0, y: 0, z: `-${props.depth}` };

    case 'left':
      return { x: props.width, y: 0, z: 0 };

    case 'bottom':
      return { x: 0, y: props.height, z: 0 };

    default: return { x: 0, y: 0, z: 0 };
  }
}

const getBoxStyle = (props) => {
  const { x, y, z } = props.position || { x: 0, y: 0, z: 0};
  const { x: rx, y: ry, z: rz } = props.rotation || { x: 0, y: 0, z: 0 };
  
  return css`
    transform: translate3d(${x}, ${y}, ${z}) rotateX(${rx}deg) rotateY(${ry}deg) rotateZ(${rz}deg);
    transform-style: preserve-3d;
    width: ${props => props.width};
    height: ${props => props.height};
  `;
};

const Box = styled.article`${getBoxStyle}`;

const getBoxWallStyle = props => {
  const { width, height } = getWallSize(props);
  const { x, y, z } = getWallPosition(props);
  const { x: rx, y: ry } = getWallRotation(props);

  return css`
    background-color: ${props.backgroundColor};
    box-sizing: border-box;
    position: absolute;
    width: ${width};
    height: ${height};
    transform: translate3d(${x}, ${y}, ${z}) rotateX(${rx}deg) rotateY(${ry}deg);
    transform-origin: top left;
  `;
};

/*
  1: all
  2: top bottom front back, left right
  3: top front, left right, bottom back
  4: top front, right, bottom back, left
  5: top, right, bottom, left, front back
  6: top, right, bottom, left, front, back
*/
const getBoxColors = (colorsStr = '') => {
  const colors = [
    ...colorsStr.matchAll(/rgba?\([^)]+\)|#[a-fA-F0-9]+|\w+/g)
  ].map(match => match[0]);

  const fix = arr => arr.map(c => colors[c]);

  switch (colors.length) {
    case 1: return fix([0, 0, 0, 0, 0, 0]);
    case 2: return fix([0, 1, 0, 1, 0, 0]);
    case 3: return fix([0, 1, 2, 1, 0, 2]);
    case 4: return fix([0, 1, 2, 3, 0, 2]);
    case 5: return fix([0, 1, 2, 3, 4, 4]);
    case 6: return fix([0, 1, 2, 3, 4, 5]);

    default: return [...Array(6)].map(() => 'black');
  }
};

const BoxWall = styled.div`${getBoxWallStyle}`;

const BoxComponent = (props) => {
  const [bgTop, bgRight, bgBottom, bgLeft, bgFront, bgBack] = getBoxColors(props.backgroundColor);
  
  return (    
    <Box
      {...props}
    >
      <BoxWall
        {...props}

        backgroundColor={bgFront}
        className="box__front"
        side="front"
        style={Object.assign({}, props.styles.all, props.styles.front)}
      >
        {props.contents.front}
      </BoxWall>
      <BoxWall
        {...props}

        backgroundColor={bgBack}
        className="box__back"
        side="back"
        style={Object.assign({}, props.styles.all, props.styles.back)}
      >
        {props.contents.back}
      </BoxWall>
      <BoxWall
        {...props}

        backgroundColor={bgRight}
        className="box__right"
        side="right"
        style={Object.assign({}, props.styles.all, props.styles.right)}
      >
        {props.contents.right}
      </BoxWall>
      <BoxWall
        {...props}

        backgroundColor={bgLeft}
        className="box__left"
        side="left"
        style={Object.assign({}, props.styles.all, props.styles.left)}
      >
        {props.contents.left}
      </BoxWall>
      <BoxWall
        {...props}

        backgroundColor={bgTop}
        className="box__top"
        side="top"
        style={Object.assign({}, props.styles.all, props.styles.top)}
      >
        {props.contents.top}
      </BoxWall>
      <BoxWall
        {...props}

        backgroundColor={bgBottom}
        className="box__bottom"
        side="bottom"
        style={Object.assign({}, props.styles.all, props.styles.bottom)}
      >
        {props.contents.bottom}
      </BoxWall>
    </Box>
  );
};

export default BoxComponent;
