import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
  padding: 1em;
`;

const Title = styled.h1`
  color: black;

  font-family: 'Press Start 2P', monospace;
  font-size: 1.25em;
  padding: 0;
  margin: 0;
  white-space: nowrap;
`;

const Subtitle = styled.h2`
  color: #444;
  font-family: 'Jura', monospace;
  font-weight: 700;
  font-size: 0.875em;
  padding: 0.25em 0 0.25em 0.25em;
  margin: 0;
`;

const AppHeader = props => (
  <Header className="app-header">
    <Title className="app-header__title" mode={props.mode}>{props.title}</Title>
    <Subtitle className="app-header__subtitle" mode={props.mode}>{props.headline}</Subtitle>
  </Header>
);

export default AppHeader;
