import React, { useRef } from 'react';

import Browser from '../Browser';
import PlainSite from './Plain';
import Footer from '../Footer';

const TabletComponent = (props) => {
  const browserRef = useRef(null);

  const { title, headline, website, sections } = props;

  return (
    <Browser
      ref={browserRef}
      url={website}
    >
      <PlainSite
        sections={sections}
        title={title}
        headline={headline}
        scrollingElement={browserRef.current}
      />
      <Footer mode="plain" />
    </Browser>
  )
};

export default TabletComponent;
