import styled from 'styled-components';

export default styled.section`
  flex-direction: column;
  display: flex;
  font-family: Jura, sans-serif;
  width: calc(100% - 1em);
  padding: 0 0.5em 1em;

  h1 {
    background-color: ${props => props.titleBackgroundColor};
    color: ${props => props.titleColor};;
    font-size: 1.25em;
    font-weight: 700;
    margin: 0.5em 0;
    padding: 0.25em;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  p {
    flex-grow: 1;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 1.25em;
  }

  strong {
    font-weight: 700;
  }

  time {
    text-transform: uppercase;
    font-size: 0.75em;
    color: #444;
  }

  em {
    font-size: 0.75em;
    font-style: normal;
    text-transform: uppercase;
  }
`;
