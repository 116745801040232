String.prototype.matchAll = String.prototype.matchAll || function (regexp) { // eslint-disable-line no-extend-native
  const thisArg = this;

  const RegExpStringIterator = {
    [Symbol.iterator]: function* () {
      let match;
      while ((match = regexp.exec(thisArg)) !== null) yield match;
    }
  };

  return RegExpStringIterator;
};
