import React from 'react';

const componentFunctions = {
  '*': c => <strong key={c}>{c}</strong>,
  '_': c => <em key={c}>{c}</em>,
  '{{': c => <time key={c}>{c}</time>,
};

const reduceFn = ([arr, pos], match) => {
  const componentFn = componentFunctions[match[1]];
  return [
    [...arr, match.input.substring(pos, match.index), componentFn(match[2])],
    match.index + match[0].length
  ];
};

export const text2react = text => {
  const matches = [...text.matchAll(/(\*|_|\{\{)(.+?)(\*|_|\}\})/g)];

  const components = matches
    .reduce(reduceFn, [[], 0]);

  return <>{components[0]}{text.substring(components[1])}</>;
};