import React, { useRef } from 'react';
import styled from 'styled-components';

const ScrollingImage = styled.div`
  background-image: url(${props => props.src});
  background-size: 100%;
  max-width: ${props => props.dataOffsetTop};
  height: 12em;
  width: 100%;
  margin-bottom: 0.5em;
  transition-property: background-position;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 0, 0.7, 1.3);
`;

const ScrollingImageComponent = (props) => {
  const imageRef = useRef(null);
  const imageScrollTop = imageRef.current
    ? (imageRef.current.offsetTop - imageRef.current.offsetHeight)
    : 0;

  return (
    <ScrollingImage
      data-index={props.index}
      ref={imageRef}
      src={props.src}
      style={{
        backgroundPosition: `50% ${Math.min(100, Math.max(0, parseInt(props.pageScrollTop - imageScrollTop, 10) / 4))}%`,
      }}
    />
  );
};

export default ScrollingImageComponent;
