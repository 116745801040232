import { keyframes } from 'styled-components';

export const pulseTextAnimation = (...colors) => keyframes`
  ${colors.map((color, index) => (
    `${index * (100 / (colors.length - 1))}% {
      color: ${color};
    }`
  )).join('')}
`;

export const typeAnimation = keyframes`
  from { height: 0; }
`;

export const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const diskInsertAnimation = (pos, rot) => keyframes`
  0% {
    z-index: -1;
    transform: translate3d(${pos.x}, ${pos.y}, ${pos.z}) rotateX(${rot.x}deg) rotateY(${rot.y}deg) rotateZ(${rot.z}deg);
  }

  20% {
    transform: translate3d(${pos.x}, ${pos.y}, calc(${pos.z} + 16em)) rotateX(${rot.x}deg) rotateY(${rot.y}deg) rotateZ(${rot.z}deg);
  }

  40% {
    transform: translate3d(16em, -32em, ${pos.z}) rotateX(0) rotateY(0) rotateZ(0);
  }

  60% {
    transform: translate3d(16em, -32em, ${pos.z}) rotateX(0) rotateY(0) rotateZ(0);
  }

  80% {
    transform: translate3d(22.25em, -9.625em, 14em) rotateX(90deg) rotateY(0) rotateZ(0);
  }

  100% {
    z-index: -1;
    transform: translate3d(22.25em, -9.625em, -4.5em) rotateX(90deg) rotateY(0) rotateZ(0);
  }
`;

export const diskEjectAnimation = (pos, rot) => keyframes`
  0% {
    z-index: -1;
    transform: translate3d(22.25em, -9.625em, -4.5em) rotateX(90deg) rotateY(0) rotateZ(0);
  }
  
  50% {
    transform: translate3d(22.25em, -9.625em, calc(${pos.z} + 16em)) rotateX(90deg) rotateY(0) rotateZ(0);
  }
  
  80% {
    z-index: -1;
    transform: translate3d(${pos.x}, ${pos.y}, calc(${pos.z} + 16em)) rotateX(${rot.x}deg) rotateY(${rot.y}deg) rotateZ(${rot.z}deg);
  }

  100% {
    z-index: -1;
    transform: translate3d(${pos.x}, ${pos.y}, ${pos.z}) rotateX(${rot.x}deg) rotateY(${rot.y}deg) rotateZ(${rot.z}deg);
  }
`;
