import { createGlobalStyle } from 'styled-components';

const AppStyle = createGlobalStyle`
  html, body {
    font-size: 16px;
  }
  
  body {
    background-color: ${props => props.mode === 'plain' ? 'white' : 'black'};
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    ::selection {
      background: gold;
    }
  }
`;

export default AppStyle;
