import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { typeAnimation, pulseTextAnimation } from '../helpers/animations';

const Screen = styled.article`
  width: ${props => props.mode === 'plain' ? '100%' : '37.5em'};
  height: ${props => props.mode === 'plain' || props.mode === 'newspaper' ? 'auto' : '25em'};
  ${props => props.mode !== 'plain' ? css`
    transform: translate3d(2.5em, 0, 0);
    transform-style: preserve-3d;
  ` : ''}
`;

const getFrontStyle = props => css`
  width: 37.5em;
  height: 25em;
  min-height: 25em;
  position: absolute;
  box-sizing: border-box;
  color: white;
  transform-origin: top center;
  transition-property: background-color, border-radius, transform;
  transition-duration: 1s;
  
  ${(
    props.mode === 'plain' ? css`
      width: 100%;
      height: auto;
    ` :
    props.mode === 'newspaper' ? css`
      background-color: white;
      filter: sepia(50%);
      height: auto;
      padding: 0;
      position: static;
      transform: scale(1.75);
      border-bottom-left-radius: 0.125em;
      border-bottom-right-radius: 0.125em;

      & > article {
        box-shadow: 0 0 1px 1px black;
        background-color: white;
        position: static;
        border-bottom-left-radius: 0.125em;
        border-bottom-right-radius: 0.125em;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 10px;
        right: 0;
        bottom: 10px;
        background-color: white;
        z-index: -1;
        transform: rotate(1deg);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 10px;
        left: 0;
        bottom: 10px;
        background-color: white;
        z-index: -1;
        transform: rotate(-1deg);
      }
    ` :
    props.mode === 'tablet' ? css`
      background-color: whitesmoke;
      border: 5px outset rgb(238, 238, 238);
      border-radius: 2em;
      padding: 2em;
      transform: scale(1.5);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-25%, -50%);
        background-color: whitesmoke;
        width: 1em;
        height: 1em;
        border-radius: 100%;
        border: 2px inset lightgray;
      }
    `:
    css`
      background-color: rgb(200, 200, 200);
      border: 5px outset rgb(238, 238, 238);
      padding: 2em;
      transform: scale(1);
    `
  )}
      
  ${props.mode !== 'newspaper' && props.mode !== 'plain' ? css`
    &::before {
      content: '';
      box-sizing: border-box;
      border: 2px inset white;
      background-color: black;
      position: absolute;
      top: 2em;
      left: 2em;
      right: 2em;
      bottom: 2em;
    }
  ` : ''}
`;

const Front = styled.div`${getFrontStyle}`;

const Content = styled.article`
  opacity: ${props => props.hideContent ? 0 : 1};
  display: flex;
  font-family: monospace;
  font-size: ${props => props.mode === 'desktop' ? 1 : 1.25}em;
  position: absolute;
  margin: 0;
  padding: 0.5em;
  width: ${props => (
    props.mode === 'newspaper' || props.mode === 'plain' ? '100%' :
    props.mode === 'desktop' ? 'calc(100% - 4em)' :
    'calc(100% - 3.2em)'
  )};
  box-sizing: border-box;
  color: black;
  white-space: pre-wrap;
  overflow: hidden;
  animation: ${props => props.disableAnimation || props.mode !== 'desktop' ? '' : css`${typeAnimation} 4s steps(30, end) ${props => props.hasDisk ? 'running' : 'paused'}, `}${pulseTextAnimation('green', 'limegreen')} 2s infinite alternate;
  transition-property: opacity;
  transition-duration: ${props => props.hideContent ? 0 : 0.5}s;

  ${(
    props => props.disableAnimation ? css`
    height: 0;
    padding: 0;
  ` :
    props.mode === 'newspaper' || props.mode === 'plain' ? css`
    height: auto;
  ` :
    props.mode === 'desktop' ? css`
    height: calc(100% - 4em);
  ` : css`
    height: calc(100% - 3.2em);
  `
  )};
`;

const getBackStyle = props => css`
  opacity: ${props => props.mode !== 'desktop' ? 0 : 1};
  transition-property: opacity;
  transition-delay: ${props => props.mode !== 'desktop' ? 0 : 1}s;
  width: 37.5em;
  background: rgb(100, 100, 100);
  position: absolute;
  padding: 1em;
  box-sizing: border-box;
  height: 18.75em;
  transform: translate3d(0, 3.125em, -18.75em) rotateY(180deg);
`;

const Back = styled.div`${getBackStyle}`;

const getSideStyle = props => css`
  opacity: ${props.mode !== 'desktop' ? 0 : 1};
  transition-property: opacity;
  transition-delay: ${props.mode !== 'desktop' ? 0 : 1}s;
  position: absolute;
  height: 18.75em;
  border-left: 18.75em solid rgb(150, 150, 150);
  border-top: 3.125em solid transparent;
  border-bottom: 3.125em solid transparent;
  transform: rotateY(90deg) translateZ(${props.right ? 28.09375 : -9.375}em) translateX(9.375em);
`;

const Side = styled.div`${getSideStyle}`;

const getCoverStyle = props => css`
  opacity: ${props.mode !== 'desktop' ? 0 : 1};
  transition-property: opacity;
  transition-delay: ${props.mode !== 'desktop' ? 0 : 1}s;
  background-color: rgb(180, 180, 180);
  position: absolute;
  width: 37.5em;
  
  height: 18.75em;
  transform: ${props => props.top
    ? 'rotateX(99deg) translateZ(9.125em) translateY(-8.125em)'
    : 'rotateX(81deg) translateZ(-245px) translateY(-114px)'
  };
`;

const Cover = styled.div`${getCoverStyle}`;

const Stand = styled.div`
  opacity: ${props => props.mode !== 'desktop' ? 0 : 1};
  transition-property: opacity;
  transition-delay: ${props => props.mode !== 'desktop' ? 0 : 1}s;
  transform: translate3d(16.25em, 22.75em, -5em);
  transform-style: preserve-3d;
  position: absolute;
`;

const StandWall = styled.div`
  position: absolute;
  overflow: hidden;
  margin: 0 auto;
	width: 5em;
	height: 5em;
	background-color: ${props => (props.left || props.right) ? `rgb(150, 150, 150)` : `rgb(180, 180, 180)`};
  transform: ${props => [
    (props.left || props.right) ? `rotateY(90deg)` : '',
    props.left ? `translate3d(0, 0, 5em)` : '',
    props.bottom ? `translate3d(0, 0, -5em)` : ''
  ].join(' ')};
  transform-origin: left;
`;

const ScreenComponent = (props) => {
  const [prevContent, setPrevContent] = useState(props.content);
  const [hasDisk, setHasDisk] = useState(false);
  const [disableAnimation, setDisableAnimation] = useState(false);

  const { insertedDisk, selectedDisk, content } = props;

  useEffect(() => {
    setPrevContent(content ? content : prevContent);
  }, [content, prevContent]);

  useEffect(() => {
    // const nextHasDisk = insertedDisk !== -1 && selectedDisk === -1;
    const nextHasDisk = selectedDisk === -1;
    setHasDisk(nextHasDisk);

    let to;
    if (nextHasDisk) {
      setDisableAnimation(true);
      to = setTimeout(() => setDisableAnimation(false), 250);
    }

    return () => {
      clearTimeout(to);
    }
  }, [selectedDisk, insertedDisk]);

  return (    
    <Screen
      className="screen"
      mode={props.mode}
    >
      <Front
        className="screen__front"
        mode={props.mode}
      >
        <Content
          content={props.content}
          hideContent={props.disableContent}
          disableAnimation={disableAnimation}
          hasDisk={hasDisk}
          mode={props.mode}
      >
          {prevContent}
        </Content>
      </Front>
      <Back
        className="screen__back"
        mode={props.mode}
      />
      <Side className="screen__right" right mode={props.mode} />
      <Side className="screen__left"  left mode={props.mode} />
      <Cover className="screen_top" top mode={props.mode} />
      <Cover className="screen_bottom" bottom mode={props.mode} />
      <Stand mode={props.mode}>
        <StandWall front />
        <StandWall right />
        <StandWall bottom />
        <StandWall left  />
      </Stand>
    </Screen>
  );
};

export default ScreenComponent;
