import { useState, useEffect } from 'react';
import { throttle } from '../helpers/throttle';

export const useMousePosition = ({ fps = 60 } = {}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  
  useEffect(() => {
    const time = 1000 / fps;
    const setPositionFromEvent = throttle(e => {
      const x = e.clientX;
      const y = e.clientY;

      setPosition({ x, y });
    }, time);

    window.addEventListener('mousemove', setPositionFromEvent);

    return () => {
      window.removeEventListener('mousemove', setPositionFromEvent);
    }
  }, [fps]);

  return position;
};
