import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const sliderTrackStyle = css`
  width: 100%;
  height: 1px;
  border-top: 1px solid white;
`;

const sliderThumbStyle = css`
  background-color: white;
  border-radius: 0.25em;
  cursor: grab;
  width: 1.5em;
  height: 2em;
  border: 1px inset whitesmoke;
  margin-top: -1em;
  ${props => (
    props.value === props.min ? css`margin-left: 1.25em;` :
    props.value === props.max ? css`margin-left: -1.25em;` :
    ''
  )}

  &:active {
    cursor: grabbing;
  }
`;


const Slider = styled.section`
  position: relative;
  font-family: Jura, sans-serif;
  width: 20em;
  height: 3em;
  margin: 0 auto 1em auto;
`;

const Values = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Value = styled.div`
  color: white;
  position: absolute;
  bottom: 0;
  left: ${props => props.index * props.size}%;
  transform: translateX(-50%);
  width: 3em;
  text-align: center;
  cursor: pointer;

  &:first-child {
    transform: translateX(0);
  }

  &:last-child {
    transform: translateX(-100%);
  }
`;

const Input = styled.input`
  -webkit-appearance: none;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${sliderThumbStyle};
  }

  &::-moz-range-thumb {
    ${sliderThumbStyle};
  }

  &::-ms-thumb {
    ${sliderThumbStyle};
  }

  &::-moz-range-track {
    ${sliderTrackStyle};
  }

  &::-webkit-slider-runnable-track {
    ${sliderTrackStyle};
  }

  &::-ms-track {
    ${sliderTrackStyle};
  }
`;

const SliderComponent = (props) => {
  const [value, setValue] = useState(props.initialValue);
  const { onChange, values } = props;

  const changeValue = (e) => {
    const index = e.target.value;
    if (index !== value) {
      setValue(index);
      onChange(values[index].value);
    }
  };

  return (
    <Slider>
      <Input
        type="range"
        min={0}
        max={values.length - 1}
        value={+value}
        step={1}
        onChange={changeValue}
      />
      <Values>
        {props.values.map((v, index, values) => (
          <Value
            key={values[index].value}
            index={index}
            size={100 / (values.length - 1)}
            onClick={() => changeValue({ target: { value: index }})}
          >{v.label}</Value>
        ))}
      </Values>
    </Slider>
  )
};

export default SliderComponent;
