import React, { useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Box from '../../Box';

const blinkLedAnimation = keyframes`
  0% {
    background-color: green;
  }

  100% {
    background-color: lightgreen;
  }
}`;

const Front = styled.div`
  &:before {
    content: '';
    position: absolute;
    background-image: linear-gradient(transparent 0, rgba(100, 100, 100) 0.125em, rgba(250, 250, 250) 0.125em, transparent 0.25em);
    background-size: 100% 0.75em;
    width: 16em;
    height: 4em;
    left: 2em;
    top: 2.5em;
  }

  &:after {
    content: '';
    position: absolute;
    top: 1em;
    left: 2em;
    width: 1em;
    height: 0.5em;
    background-color: green;
    box-sizing: border-box;
    border: 2px inset white;

    ${props => props.blink ? css`animation: ${blinkLedAnimation} 150ms linear infinite;` : ''}
  }
`;

const Floppy = styled.div`
  position: absolute;
  right: 2.5em;
  top: 2.5em;
  display: flex;
  flex-direction: column;
  border: 2px inset white;
  height: 4em;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 0.25em;
  

  &:before {
    content: '';
    box-sizing: border-box;
    background-color: black;
    border: 2px inset white;
    height: 1.5em;
    width: 18em;
    margin: 0.25em;
  }

  &:after {
    content: '';
    box-sizing: border-box;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 1em;
    width: 3em;
    background: rgb(180, 180, 180);
    border: 2px ${props => props.hasDisk ? 'outset' : 'inset'} rgb(238, 238, 238);
    margin: 0 0.25em;
  }
`;

const DriveComponent = (props) => {
  const { onClick, insertedDisk, selectedDisk } = props;
  const onFloppyClick = useCallback(() => {
    onClick(insertedDisk);
  }, [onClick, insertedDisk]);

  const hasDisk = insertedDisk !== -1 && selectedDisk === -1;

  return (
    <Box
      className="drive"
      width="42.5em"
      height="8em"
      depth="24.75em"

      style={{
        visibility: props.mode !== 'desktop' ? 'hidden' : 'visible',
        transitionProperty: `visibility`,
        transitionDelay: `${props.mode !== 'desktop' ? 0 : 1}s`,
      }}

      backgroundColor="lightgray darkgray lightgray darkgray lightgray dimgray"

      contents={{
        front: (
          <Front className="drive__front" blink={hasDisk}>
            <Floppy
              className="drive__floppy"
              hasDisk={hasDisk}
              onClick={onFloppyClick}
            />
          </Front>
        )
      }}
      position={{
        x: 0,
        y: '2.5em',
        z: '3em'
      }}
      styles={{
        front: {
          border: '3px outset rgb(238, 238, 238)',
        }
      }}
    />
  )
};

export default DriveComponent;