import { useState, useEffect } from 'react';
import { useScreenSize } from './useScreenSize';

export const useWebsiteZoom = (mode) => {
  const [zoom, setZoom] = useState(1);
  const windowSize = useScreenSize();

  useEffect(() => {
    setZoom(mode === 'plain'
      ? 1.5
      : Math.max(0.5, Math.min(1, windowSize.outerWidth / 1200))
    );
  }, [mode, windowSize.outerWidth]);

  return zoom;
};
